<script setup lang="ts">
import { inject } from 'vue'

const navigate: Function = inject('navigate')

const props = withDefaults(defineProps<{
  to?: string,
  target?: string,
}>(), { to: undefined, target: undefined })
</script>

<template>
  <a
    @click.middle="to && navigate(props.to, '_blank')"
    @click="to && navigate(props.to, props.target)"
    @click.prevent="!to"
  >
    <slot />
  </a>
</template>

<style lang="scss" scoped>
  a {
    cursor: pointer;
  }
</style>
